import Flavors from './Flavors';
import Options from './Options';

const DEFAULT_STEP = 5;
const DEFAULT_MIN = 10;

class Product {
    constructor({ id, category, name, desc, price, unity, flavors, shape, maxOpt, options, step, min, customCover }) {
        this.id = id;
        this.category = category;
        this.name = name;
        this.desc = desc || '';
        this.unity = unity || 'un';
        this.step = step || DEFAULT_STEP;
        this.min = min || DEFAULT_MIN;
        this.price = price;
        this.shape = shape;
        this.customCover = customCover;
        this.flavors = flavors ? new Flavors(this, flavors) : null;
        this.options = options ? new Options(options, maxOpt) : null;
    }

    get key() {
        return `${this.category.type}_${this.id}`;
    }

    get items() {
        if (this.flavors) {
            return this.flavors.items;
        }
        return [this];
    }

    get fullName() {
        if (this.category.type === 3) {
            return `${this.shape.name || 'Torta'} ${this.name} `;
        }
        return this.name;
    }
}
export default Product;
